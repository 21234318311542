<template>
  <fvMain>
    <label class="fv-padding-start fv-padding-end">Login</label>
        <div class="fv-row">
          <fvFormElement class="fv-col-lg-4 fv-col-md-6" label="Name" singleLine>
            <fvInput v-model="name" required placeholder="Enter your user name"/>
          </fvFormElement>
          <fvFormElement class="fv-col-lg-4 fv-col-md-6" label="Password" singleLine>
            <fvInput v-model="password" required type="password" placeholder="Enter your name" />
          </fvFormElement>
          <fvFormElement class="fv-col-lg-4 fv-col-md-6">
            <button to="/login" v-on:click="login" class="fv-size-sm fv-secondary proceed"> Proceed</button>
          </fvFormElement>
.
          <Toast :info="info"></Toast>
        </div>
  </fvMain>
</template>
<script>
    import Toast from '@/components/Toast.vue'
    export default{
         data() {
            return {
                name:'',
                password:'',
                sdk_url:'https://bijit.xyz',
                info: {
                    bool: false,
                    time: 3000,
                    msg: ''
                }
            }
        },
        components:{
            Toast
        },
        methods:{
            login(){
                const options = {
                  method: 'POST',
                  headers: {
                    Authorization: 'Bearer fbe5b19fcb49350d610f355e44deef',
                    'content-type': 'application/json'
                  },
                  body: `{"user":"${this.name}","password":"${this.password}"}`
                };
                fetch(`${this.sdk_url}/cockpit-master/api/cockpit/authUser`, options)
                  .then(response => response.json())
                  .then(response =>{
                          if(response.api_key){
                            localStorage.setItem('authenticated', true);
                            localStorage.setItem('api_key', response.api_key);
                            localStorage.setItem('username', response.user);
                            this.$router.push("/");
                          }else{
                              if(response.error){
                                  this.info.bool = true;
                                  this.info.msg = response.error;
                              }
                          }
                    })
                  .catch(err => console.log(err));
              },
              ifLoggedIn(){
                  if (localStorage.getItem('authenticated')) {
                    this.$router.push('/');
                  }
              }
            },
        created(){
            this.ifLoggedIn();
        }
            
    }
</script>
<style>
    .proceed:active{
        background-color: #23424c;
        color: white;
    }
</style>